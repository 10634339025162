import logo from './pictures/NCFirstClass_Logo-Variations-01.png'
import logo2 from './pictures/NCFirstClass_Logo-Variations-02.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} alt={logo2} className='logo'/>
      <h1 className='message'>Coming Soon</h1>
    </div>
  );
  // change
}

export default App;
